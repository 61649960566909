import React, { useEffect, useState } from "react";
import MarksInput from "./MarksInput";
import { useGeneralContext } from "../contexts/GeneralContext";

const MarksRow = (props) => {
        const [scoreProfile, setScoreProfile] = useState(props.scoreProfile);
        const [candidate, setCandidate] = useState(props.candidate);
        const [scores, setScores] = useState(scoreProfile.question_scores || {});
        const [total, setTotal] = useState(scoreProfile.total_score || "");

        const generals = useGeneralContext();
        const ID_OFFSET = generals.ID_OFFSET;

        const calculateTotal = (updatedScores) => {
                let total = 0;
                let nullFlag = true;

                Object.keys(updatedScores).forEach((question) => {
                        if (updatedScores[question] !== null) {
                                nullFlag = false;
                                total += updatedScores[question];
                        }
                });

                setTotal(nullFlag ? null : total);

                return nullFlag ? null : total;
        };

        const handleInput = (event, question) => {
                const value = event.target.value.trim();
                const updatedScores = { ...scores };

                if (value === "") {
                        updatedScores[question] = null;
                } else {
                        updatedScores[question] = parseInt(value);
                }

                const updatedTotal = calculateTotal(updatedScores);
                setScores(updatedScores);

                props.updateScoresState(props.candidate.id, updatedScores, updatedTotal);
        };

        useEffect(() => {
                setScores(props.scoreProfile?.question_scores || {});
        }, [props.scoreProfile]);

        return (
                <div className="table-row">
                        <span className="table-row-item submission-table-item">{props.candidate.first_name} {props.candidate.last_name}</span>
                        <span className="table-row-item centred submission-table-item">{props.candidate.id + ID_OFFSET}</span>
                        {props.questions.map(question => (
                                <MarksInput
                                        key={question}
                                        question={question}
                                        handleInput={handleInput}
                                        scores={scores}
                                />
                        ))}
                        <span className="table-row-item right-column value total submission-table-item">
                                {total}
                        </span>
                </div>
        );
};

export default MarksRow;