import React, { useEffect, useState } from "react";
import axios from "axios";
import Notification from "../../components/Notification";
import StudentTable from "../../components/StudentTable";
import { useTimeContext } from "../../contexts/TimeContext";

const Registration = () => {
        const [candidates, setCandidates] = useState([]);
        const times = useTimeContext();
        const start = new Date(times.REGISTRATION_START)
        const end = new Date(times.REGISTRATION_END);

        const currentDate = new Date();

        const getCandidates = async () => {
                const res = await axios.get("/api/candidates/getCandidates", {
                        withCredentials: true
                });

                return res.data;
        };

        const upsertCandidate = async (candidate, deletion = false) => {
                setCandidates((previousCandidates) => {
                        const candidateExists = previousCandidates.some(c => c.id === candidate.id);

                        if (candidateExists) {
                                if (deletion) {
                                        return previousCandidates.filter(c => c.id !== candidate.id);
                                } else {
                                        return previousCandidates.map(c =>
                                                c.id === candidate.id ? candidate : c
                                        );
                                }
                        } else {
                                return [...previousCandidates, candidate];
                        }
                })
        };

        useEffect(() => {
                try {
                        getCandidates().then(candidates => {
                                setCandidates(candidates);
                        })
                } catch (err) {
                        console.log(err)
                }

        }, []);

        return (
                <>
                        {(currentDate < start) ? (
                                <Notification
                                        text={<>Registration is not yet open. </>}
                                        subtext={<>It will open on 16th October 2024 at 07:00.</>}
                                />
                        ) : ((currentDate > end) ? (
                                <Notification
                                        text={<>Registration is closed. </>}
                                        subtext={<>You can no longer register candidates for the 2024 BAO.</>}
                                />
                        ) : (
                                <>
                                        <Notification
                                                text={<>You can add, edit and remove students until round one closes on <span className="med">Friday 6th December</span > at 18:00.</>}
                                                subtext={<> You will not be able to view this page after this deadline.</>}
                                        />
                                        <StudentTable candidates={candidates} upsertCandidate={upsertCandidate} />
                                </>))}
                </>
        );
};

export default Registration;