import { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Authentication from './pages/Authentication';
import Portal from './pages/Portal';
import Cookies from 'js-cookie';
import axios from 'axios';

function App() {
  const [defaultPath, setDefaultPath] = useState("/");
  const [isSmallDevice, setIsSmallDevice] = useState(false);

  const isLoggedIn = async () => {
    try {
      const res = await axios.get("/api/teachers/isLoggedIn", {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });
      return res.data.isLoggedIn;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallDevice(window.innerWidth < 1100);
    };

    checkScreenSize();

    window.addEventListener('resize', checkScreenSize);

    isLoggedIn().then(isLoggedIn => {
      if (isLoggedIn) {
        setDefaultPath("/students/registrations");
      } else {
        setDefaultPath("/login");
      }
    });
  }, []);

  return (
    <>
      {isSmallDevice ? (
        <div style={{ textAlign: 'center', marginTop: '50px', color: 'white', fontSize: "14px" }}>
          <p>Please use a desktop to view this page.</p>
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<Authentication page="login" />} />
          <Route path="/register" element={<Authentication page="register" />} />
          <Route path="/" element={<Navigate to={defaultPath} replace />} />
          <Route path="*" element={<Portal />} />
        </Routes>
      )}
    </>
  );
}

export default App;
