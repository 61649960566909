import React, { useEffect } from "react";
import MarksRow from "./MarksRow";
import { useQuestionsContext } from "../contexts/QuestionsContext";

const MarksTable = (props) => {
        const questions = useQuestionsContext();

        // Ensure candidates and scores are available
        if (!props.candidates || !props.scores) {
                return <div>No data available</div>;
        }

        return (
                <>
                        <div className="table marks-table">
                                <div className="table-header">
                                        <span className="table-header-title">Name</span>
                                        <span className="table-header-title centred">Number</span>
                                        {questions.map(question => (
                                                <span key={question} className="table-header-title centred value">{question}</span>
                                        ))}
                                        <span className="table-header-title right-column value total">Total</span>
                                </div>
                                <div className="table-content submission-table">
                                        {props.candidates.map(candidate => {
                                                const scoreProfile = props.scores.find(score => score.candidate_id === candidate.id) || {};  // Use empty object as fallback

                                                return (
                                                        <MarksRow
                                                                key={candidate.id}
                                                                candidate={candidate}
                                                                scoreProfile={scoreProfile}
                                                                updateScoresState={props.updateScoresState}
                                                                questions={questions}
                                                        />
                                                );
                                        })}
                                </div>
                        </div>
                </>
        );
};

export default MarksTable;