import React, { useState, createContext, useContext, useEffect } from 'react';

export const MenuContext = createContext();

export const MenuContextProvider = (props) => {
        const [menuContent, setMenuContent] = useState(
                [
                        {
                                title: 'MY STUDENTS',
                                items: [
                                        { name: 'Registrations', route: '/registrations', icon: 'clipboard' },
                                        { name: 'Submissions', route: '/submissions', icon: 'check' },
                                        { name: 'Results & certificates', route: '/results', icon: 'trophy' },
                                ],
                                route: '/students',
                        },
                        {
                                title: 'COMPETITION INFO',
                                items: [
                                        { name: 'Timeline', route: '/timeline', icon: 'timeline' },
                                        { name: 'Documents', route: '/documents', icon: 'documents' },
                                ],
                                route: '/competition',
                        },
                        {
                                title: 'PROFILE',
                                items: [
                                        { name: 'Profile settings', route: '/settings', icon: 'cog' },
                                ],
                                route: '/profile',
                        },
                ]
        );

        return (
                <MenuContext.Provider value={menuContent}>
                        {props.children}
                </MenuContext.Provider>
        );
};

export const useMenuContext = () => useContext(MenuContext);
