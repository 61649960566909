import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Register = () => {
        const [first_name, setFirstName] = useState("");
        const [last_name, setLastName] = useState("");
        const [email, setEmail] = useState("");
        const [school, setSchool] = useState("");
        const [position, setPosition] = useState("");
        const [error, setError] = useState("");

        const navigate = useNavigate();

        const register = async (first_name, last_name, email, school, position) => {
                try {
                        const res = await axios.post("/api/teachers/register", {
                                first_name, last_name, email, school, position
                        }, {
                                headers: {
                                        'Content-Type': 'application/json'
                                },
                                withCredentials: true
                        },);
                        ;
                        navigate("/students/registrations");
                } catch (err) {
                        setError("Something went wrong. Please try again.");
                }
        };

        const handleRegister = () => {
                if (!first_name || !last_name || !email || !school || !position) {
                        setError("Please complete all fields.");
                } else {
                        setError("");
                        register(first_name, last_name, email, school, position);
                };
        }

        return (
                <div className="auth-content">
                        <div className="auth-content-title">Create a teacher <br />account</div>
                        <div className="auth-content-subtitle">
                                Please create a new account for 2024. After signing up you will receive a teacher code.
                        </div>
                        {error && <div className="error-message">{error}</div>}
                        <div className="auth-form">
                                <div className="split-label-group">
                                        <div className="label">First Name</div>
                                        <div className="label">Last Name</div>
                                </div>
                                <div className="split-input-group">
                                        <input type="text" className="input" placeholder="Jane" onChange={(e) => { setFirstName(e.target.value) }} />
                                        <input type="text" className="input" placeholder="Smith" onChange={(e) => { setLastName(e.target.value) }} />
                                </div>
                                <div className="label">Your Email</div>
                                <input className="input" placeholder="jane@school.com" onChange={(e) => { setEmail(e.target.value) }} />
                                <div className="label">School Name</div>
                                <input className="input" placeholder="School name" onChange={(e) => { setSchool(e.target.value) }} />
                                <div className="label">Your Position</div>
                                <input className="input" placeholder="Teacher of Computer Science" onChange={(e) => { setPosition(e.target.value) }} />
                                <div className="form-button" onClick={handleRegister}>
                                        <span>
                                                Create account
                                        </span>
                                </div>
                                <div className="auth-alt-text">
                                        Already have an account? <Link to="/login" className="auth-link">Sign in</Link>
                                </div>
                        </div>
                </div>
        );
};

export default Register;