import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";

const RegisterModal = (props) => {
        const [first_name, setFirstName] = useState("");
        const [last_name, setLastName] = useState("");
        const [year_group, setYearGroup] = useState("");
        const [show, setShow] = useState(false);
        const [error, setError] = useState("");

        const handleClose = () => {
                setFirstName("");
                setLastName("");
                setYearGroup("");

                setShow(false);
                setError("");
        }

        const handleShow = () => setShow(true);

        const addCandidate = async () => {
                try {
                        const res = await axios.post("/api/candidates/registerCandidate", {
                                first_name: first_name,
                                last_name: last_name,
                                year_group: year_group
                        }, {
                                withCredentials: true
                        });

                        if (res.data.error) {
                                setError(res.data.error);
                        } else {
                                setError("");
                        }

                        return res;
                } catch (err) {
                        setError("An error occurred. Please try again.");
                }
        };

        const handleAdd = async () => {
                if (!first_name || !last_name || !year_group) {
                        setError("Please complete all fields.");
                        return;
                }

                const res = await addCandidate();

                if (!error && res) {
                        props.upsertCandidate(res.data.candidate);
                        handleClose();
                } else if (!res) {
                        setError("An error occurred. Please try again.");
                }
        };

        return (
                <>
                        <div className="add-button" onClick={handleShow}>
                                <img src="../icons/plus.svg" />
                                <span>Add Student</span>
                        </div>

                        <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                        <Modal.Title className="modal-title">
                                                Register a new student<br />
                                                <div className="modal-subtitle">Enter the details of the student you’re registering below.</div>
                                        </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                        {error && <div className="error-message">{error}</div>}
                                        <div className="split-label-group">
                                                <div className="label">First Name</div>
                                                <div className="label">Last Name</div>
                                                <div className="label small-label">Year</div>
                                        </div>
                                        <div className="split-input-group">
                                                <input type="text" className="input" placeholder="Jane" onChange={(e) => { setFirstName(e.target.value.trim()) }} autoFocus />
                                                <input type="text" className="input" placeholder="Smith" onChange={(e) => { setLastName(e.target.value.trim()) }} />
                                                <input type="text" className="input small-input" placeholder="12" onChange={(e) => { setYearGroup(e.target.value.trim()) }} />
                                        </div>
                                </Modal.Body>
                                <Modal.Footer>
                                        <div className="close-modal-btn" onClick={handleClose}>
                                                Cancel
                                        </div>
                                        <div className="add-modal-btn" onClick={handleAdd}>
                                                Add
                                        </div>
                                </Modal.Footer>
                        </Modal>
                </>

        );
};

export default RegisterModal;