import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
        const [email, setEmail] = useState("");
        const [teacher_code, setTeacherCode] = useState("");
        const [error, setError] = useState("");
        const navigate = useNavigate();


        const login = async (email, teacher_code) => {
                try {
                        const res = await axios.post("/api/teachers/login", {
                                email,
                                teacher_code
                        }, {
                                headers: {
                                        'Content-Type': 'application/json'
                                },
                                withCredentials: true
                        },);

                        navigate("/students/registrations");
                } catch (err) {
                        setError("Invalid email or teacher code.");
                }
        };

        const handleLogin = () => {
                if (!email || !teacher_code) {
                        setError("Please complete all fields.");
                } else {
                        setError("");
                        login(email, teacher_code);
                }
        };

        return (
                <div className="auth-content">
                        <div className="auth-content-title">Sign in to your <br />account</div>
                        <div className="auth-content-subtitle">
                                Please create a new account for 2024. You should have received a teacher code after creating an account.
                        </div>
                        {error && <div className="error-message">{error}</div>}
                        <div className="auth-form">
                                <div className="label">Your Email</div>
                                <input className="input" placeholder="jane@school.com" onChange={(e) => { setEmail(e.target.value) }} />
                                <div className="label">Teacher Code</div>
                                <input className="input" placeholder="ABC1234" onChange={(e) => { setTeacherCode(e.target.value) }} />
                                <div className="form-button" onClick={handleLogin}>
                                        <span>
                                                Sign in
                                        </span>
                                </div>
                                <div className="auth-alt-text">
                                        Don't have an account? <Link to="/register" className="auth-link">Sign up as a teacher</Link>
                                </div>
                        </div>
                </div>
        );
};

export default Login;