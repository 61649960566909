import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMenuContext } from "../contexts/MenuContext";

const TitleBar = () => {
        const menuContent = useMenuContext();
        const location = useLocation();
        const [sectionPath, pagePath] = location.pathname.split("/").slice(1);
        const [pageSection, setPageSection] = useState("");
        const [pageTitle, setPageTitle] = useState("");

        useEffect(() => {
                menuContent.forEach((section) => {
                        if (section.route === `/${sectionPath}`) {
                                setPageSection(section.title);
                        }

                        section.items.forEach((item) => {
                                if (item.route === `/${pagePath}`) {
                                        setPageTitle(item.name);
                                }
                        });
                });
        });



        return (
                <div className="section-path">
                        <div className="path-section menu-section-title">{pageSection}</div>
                        <img className="path-icon" src="../icons/chevron-right-blue.svg" />
                        <div className="path-name">{pageTitle}</div>
                </div>
        );
}

export default TitleBar;