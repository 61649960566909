import React from "react";
import ProfileBox from "./ProfileBox";
import { useTeacherContext } from "../contexts/TeacherContext";
import { useGeneralContext } from "../contexts/GeneralContext";

const Header = () => {
        const teacher = useTeacherContext();
        const generals = useGeneralContext();

        return (
                <div className="navbar">
                        <img src="../icon.png" className="nav-icon" />
                        <div className="nav-title">BAO <span className="lb-text">Portal</span></div>
                        <div className="nav-year">{`${generals.YEAR}/${generals.YEAR % 100 + 1}`}</div>
                        <ProfileBox teacher={teacher} />
                </div>
        );
}

export default Header;