import React, { useState } from "react";
import DocumentRow from "./DocumentRow";

const DocumentsTable = (props) => {
        return (
                <div className="documents-table">
                        <div className="documents-table-header">
                                <span className="documents-table-header-title column-name">Name</span>
                                <span className="documents-table-header-title column-uploaded">Uploaded</span>
                                <span className="documents-table-header-title column-owner">Owner</span>
                        </div>
                        {props.documents.length > 0 ? (
                                <>
                                        {props.documents.map((document) => (
                                                <DocumentRow document={document} key={document.file_name} />
                                        ))}
                                </>
                        ) : (
                                <div className="document-row">
                                        <span className="no-documents">No documents available</span>
                                </div>
                        )}
                </div>
        );
};

export default DocumentsTable;