import React from "react";
import Header from '../components/Header';
import MainContent from '../components/MainContent';
import { TeacherContextProvider } from '../contexts/TeacherContext';
import { MenuContextProvider } from '../contexts/MenuContext';
import { TimeContextProvider } from "../contexts/TimeContext";
import { GeneralContextProvider } from "../contexts/GeneralContext";

const Portal = () => {
        return (
                <MenuContextProvider>
                        <TeacherContextProvider>
                                <GeneralContextProvider>
                                        <TimeContextProvider>
                                                <Header />
                                                <MainContent />
                                        </TimeContextProvider>
                                </GeneralContextProvider>
                        </TeacherContextProvider>
                </MenuContextProvider>
        );
}

export default Portal;
