import React from "react";

const Event = (props) => {
        return (
                <div className="event">
                        {props.event.startDate <= props.currentDate && props.event.endDate >= props.currentDate ? (
                                <>
                                        <img className="event-icon" src="../event-current.png" />
                                        <div className="ripple-ring-container"></div>
                                </>
                        ) : (props.event.endDate <= props.currentDate ? (
                                <img className="event-icon" src="../event-past.png" />
                        ) : (
                                <img className="event-icon" src="../event-future.png" />
                        ))}
                        <div className="event-content">
                                <div className="event-title">{props.event.title}</div>
                                <div className="event-date">{props.event.dateString}</div>
                                <div className="event-description">{props.event.description}</div>
                        </div>
                </div>
        );
};

export default Event;