import React from "react";
import Menu from "./Menu";
import ContentArea from "./ContentArea";
import UnverifiedTab from "./UnverifiedTab";
import { useTeacherContext } from "../contexts/TeacherContext";

const MainContent = () => {
        const teacher = useTeacherContext();
        const verified = teacher.verified;

        if (verified) {
                return (
                        <div className="body">
                                <Menu />
                                <ContentArea />
                        </div>
                );
        } else {
                return (
                        <UnverifiedTab />
                )
        }

}

export default MainContent;