import React, { useState, createContext, useContext } from 'react';

export const TimeContext = createContext();

export const TimeContextProvider = (props) => {
        const times = {
                REGISTRATION_START: "2024-10-16T07:00:00",
                REGISTRATION_END: "2024-12-06T18:00:00",
                SUBMISSION_START: "2024-11-11T07:00:00",
                SUBMISSION_END: "2024-12-11T18:00:00",
                RESULTS_RELEASE: "2025-01-08T07:00:00"
        };

        return (
                <TimeContext.Provider value={times}>
                        {props.children}
                </TimeContext.Provider>
        );
};

export const useTimeContext = () => useContext(TimeContext);