import React from "react";

const ResultCard = (props) => {
        const level = props.result.level.toLowerCase();
        const finalist = props.result.finalist;

        return (
                <div className={`result-card ${finalist ? "finalist" : level}-card`}>
                        <div className={`result-level ${level}-level`}>{props.result.level != "AWARD" ? props.result.level : ""} AWARD</div>
                        <div className="result-card-name">{props.result.name}</div>
                        <div className="result-card-number">{props.result.number}</div>
                        <div className="result-download" onClick={() => { props.generateCertificate(props.result) }}>
                                <img src="../download-icon.png" />
                                Certificate
                        </div>
                        <img src={`../icons/${level}-trophy.svg`} className="result-trophy" />
                        <div className="result-marks">{props.result.marks} mark{props.result.marks != 1 ? "s" : ""}</div>
                        {props.result.finalist && <div className="result-finalist">FINALIST</div>}
                </div>
        );
};

export default ResultCard;