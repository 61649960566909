import React, { useState } from "react";
import { useLocation } from "react-router-dom";

const MenuItem = (props) => {
        const [selected, setSelected] = useState(false);
        const location = useLocation();

        if (`/${location.pathname.split('/').slice(-1)[0]}` === props.item.route && !selected) {
                setSelected(true);
        }

        return (
                <a className={`menu-item ${selected ? "menu-item-selected" : ""}`} href={`${props.section.route}${props.item.route}`}>
                        <img
                                className="menu-icon"
                                src={`../icons/${props.item.icon}-${selected ? "white" : "blue"}.svg`}
                        />
                        <span>{props.item.name}</span>
                </a>
        );
}

export default MenuItem;