import React from "react";
import axios from "axios";

const DocumentRow = (props) => {
        const openDocument = () => {
                window.location.href = "/api/documents/getDocument/" + props.document.id;
        };

        return (
                <div className="document-row" onClick={openDocument}>
                        <div className="document-row-name column-name">
                                <img className="document-image" src="../pdf-icon.png" />
                                <div className="document-name-data">
                                        <span className="document-name-title">{props.document.title}</span><br />
                                        <span className="document-name-filename">{props.document.file_name}</span>
                                </div>
                        </div>
                        <span className="document-row-uploaded column-uploaded">{props.document.uploaded}</span>
                        <span className="document-row-owner column-owner">British Algorithmic Olympiad</span>
                </div>
        );
};

export default DocumentRow;