import React, { useEffect, useState } from "react";
import axios from "axios";
import MarksTable from "../../components/MarksTable";
import Notification from "../../components/Notification";
import { QuestionsContextProvider } from "../../contexts/QuestionsContext";
import { useTimeContext } from "../../contexts/TimeContext";

const Submission = () => {
        const [candidates, setCandidates] = useState([]);
        const [scores, setScores] = useState([]);
        const [loading, setLoading] = useState(true);
        const [saveSuccess, setSaveSuccess] = useState(false);
        const [error, setError] = useState("");
        const times = useTimeContext();
        const start = new Date(times.SUBMISSION_START)
        const end = new Date(times.SUBMISSION_END);

        const currentDate = new Date();

        const getCandidates = async () => {
                const res = await axios.get("/api/candidates/getCandidates", {
                        withCredentials: true
                });
                return res.data;
        };

        const getScoreProfiles = async () => {
                const res = await axios.get("/api/scoreProfiles/getScoreProfiles", {
                        withCredentials: true
                });
                return res.data;
        };

        const updateScores = async () => {
                try {
                        const res = await axios.post("/api/scoreProfiles/updateCandidatesScores", {
                                scores
                        }, {
                                withCredentials: true
                        });

                        if (res.data.error) {
                                setError(res.data.error);
                                alert(res.data.error);
                                setSaveSuccess(false);
                        } else {
                                setError("");
                                setSaveSuccess(true);
                                setTimeout(() => {
                                        setSaveSuccess(false);
                                }, 1500);
                        }

                        return res;
                } catch (err) {
                        setError("An error occurred. Please try again.");
                        setSaveSuccess(false);
                        alert(err);
                }
        };

        const updateScoresState = (candidateId, updatedScores, total) => {
                setScores((prevScores) => {
                        return prevScores.map((score) =>
                                score.candidate_id === candidateId ? { ...score, question_scores: updatedScores, total_score: total } : score
                        );
                });
        };

        useEffect(() => {
                const fetchData = async () => {
                        try {
                                const candidatesList = await getCandidates();
                                const scoreProfiles = await getScoreProfiles();
                                setCandidates(candidatesList);
                                setScores(scoreProfiles);
                        } catch (err) {
                                console.log(err);
                                setError("An error occurred while fetching data.");
                        } finally {
                                setLoading(false); // Set loading to false when the data is fetched
                        }
                };
                fetchData();
        }, []);

        if (loading) {
                return <div>Loading...</div>; // Render a loading message or spinner
        }

        return (
                <>
                        {(currentDate < start) ? (
                                <Notification
                                        text={<>Submission is not yet open. </>}
                                        subtext={<>It will open on 11th November 2024 at 07:00.</>}
                                />
                        ) : ((currentDate > end) ? (
                                <Notification
                                        text={<>Submission is closed. </>}
                                        subtext={<>You can no longer submit marks for the 2024 BAO.</>}
                                />
                        ) : (
                                <>
                                        <div className={`save-marks-btn ${saveSuccess && "save-marks-btn-success"}`} onClick={updateScores}><span>{saveSuccess && <img className="save-marks-check" src="../../icons/check-blue.svg" />}Save Changes</span></div>
                                        <Notification
                                                text={<>You can edit and submit marks until <span className="med">Wednesday 11th December</span> at 18:00.</>}
                                                subtext={<>Don't forget to save the changes you make.</>}
                                        />
                                        <QuestionsContextProvider>
                                                <MarksTable candidates={candidates} scores={scores} updateScoresState={updateScoresState} />
                                        </QuestionsContextProvider>
                                </>))}
                </>
        );
};

export default Submission;