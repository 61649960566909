import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useGeneralContext } from "../contexts/GeneralContext";

const CandidateRow = (props) => {
        const candidate = props.candidate;

        const generals = useGeneralContext();
        const ID_OFFSET = generals.ID_OFFSET;

        const [first_name, setFirstName] = useState(candidate.first_name);
        const [last_name, setLastName] = useState(candidate.last_name);
        const [year_group, setYearGroup] = useState(candidate.year_group);
        const [candidate_id, setCandidateID] = useState(candidate.id);
        const [show, setShow] = useState(false);
        const [error, setError] = useState("");

        const handleClose = () => {
                setShow(false);
                setError("");
        }

        const handleShow = () => setShow(true);

        const updateCandidate = async () => {
                try {
                        const res = await axios.patch("/api/candidates/updateCandidate", {
                                first_name, last_name, year_group, candidate_id
                        }, {
                                withCredentials: true
                        });

                        if (res.data.error) {
                                setError(res.data.error);
                        } else {
                                setError("");
                        }

                        return res;
                } catch (err) {
                        setError("An error occurred. Please try again.");
                }
        };

        const deleteCandidate = async () => {
                try {
                        const res = await axios.post("/api/candidates/deleteCandidate", {
                                candidate_id
                        }, {
                                withCredentials: true
                        });

                        if (res.data.error) {
                                setError(res.data.error);
                        } else {
                                setError("");
                        }

                        return res;
                } catch (err) {
                        setError("An error occurred. Please try again.");
                }
        };

        const handleUpdate = async () => {
                if (!first_name || !last_name || !year_group) {
                        setError("Please complete all fields.");
                        return;
                }

                const res = await updateCandidate();

                if (!error) {
                        props.upsertCandidate(res.data.candidate);
                        handleClose();
                }
        };

        const handleDelete = async () => {
                const res = await deleteCandidate();

                if (!error) {
                        props.upsertCandidate({ id: candidate_id }, true);
                        handleClose();
                }
        };

        console.log(candidate)

        return (
                <>
                        <div className="table-row" key={candidate.id} onClick={handleShow}>
                                <span className="table-row-item student-row-item">{candidate.first_name}</span>
                                <span className="table-row-item student-row-item">{candidate.last_name}</span>
                                <span className="table-row-item centred student-row-item">{candidate.id + ID_OFFSET}</span>
                                <span className="table-row-item centred student-row-item">{candidate.year_group}</span>
                                {/* <span className="table-row-item centred student-row-item">{candidate.login_code}</span> */}
                                <span className="table-row-item right-column student-row-item">
                                        <img src="../icons/edit.svg" />
                                </span>
                        </div>

                        <Modal show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                        <Modal.Title className="modal-title update-modal-title">
                                                Update&nbsp;
                                                {candidate.first_name +
                                                        (candidate.first_name[candidate.first_name.length - 1].toLowerCase() === "s" ? "'" : "'s")
                                                } details<br />
                                                <div className="modal-subtitle">Edit this student's information below.</div>
                                        </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                        {error && <div className="error-message">{error}</div>}
                                        <div className="split-label-group">
                                                <div className="label">First Name</div>
                                                <div className="label">Last Name</div>
                                                <div className="label small-label">Year</div>
                                        </div>
                                        <div className="split-input-group">
                                                <input type="text" className="input input-update" value={first_name} onChange={(e) => { setFirstName(e.target.value.trim()) }} autoFocus />
                                                <input type="text" className="input input-update" value={last_name} onChange={(e) => { setLastName(e.target.value.trim()) }} />
                                                <input type="text" className="input small-input input-update" value={year_group} onChange={(e) => { setYearGroup(e.target.value.trim()) }} />
                                        </div>
                                </Modal.Body>
                                <Modal.Footer>
                                        <div className="delete-modal-btn" onClick={handleDelete}>
                                                Delete
                                        </div>
                                        <div className="close-modal-btn" onClick={handleClose}>
                                                Cancel
                                        </div>
                                        <div className="add-modal-btn" onClick={handleUpdate}>
                                                Save
                                        </div>
                                </Modal.Footer>
                        </Modal>
                </>

        );
};

export default CandidateRow;