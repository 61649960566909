import React from "react";
import RegisterModal from "./RegisterModal";
import CandidateRow from "./CandidateRow";

const StudentTable = (props) => {
        return (
                <div className="table">
                        <div className="table-header">
                                <span className="table-header-title">First Name</span>
                                <span className="table-header-title">Last Name</span>
                                <span className="table-header-title centred">Number</span>
                                <span className="table-header-title centred">Year Group</span>
                                {/* <span className="table-header-title centred">Login Code</span> */}
                                <span className="table-header-title right-column">
                                        <RegisterModal upsertCandidate={props.upsertCandidate} />
                                </span>
                        </div>
                        <div className="table-content">
                                {props.candidates.map(candidate => {
                                        return (
                                                <CandidateRow candidate={candidate} key={candidate.id} upsertCandidate={props.upsertCandidate} />
                                        );
                                })}
                        </div>
                </div>
        );
}

export default StudentTable;