import React, { useState, createContext, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMenuContext } from './MenuContext';
import axios from "axios";

export const TeacherContext = createContext();

export const TeacherContextProvider = (props) => {
        const [teacherData, setTeacherData] = useState({});
        const [loading, setLoading] = useState(true);  // Add a loading state
        const [error, setError] = useState(null);      // Add an error state
        const menuContent = useMenuContext();
        const location = useLocation();
        const navigate = useNavigate();

        const getTeacherData = async () => {
                try {
                        const res = await axios.get("/api/teachers/getTeacher", {
                                headers: {
                                        'Content-Type': 'application/json',
                                },
                                withCredentials: true,
                        });

                        return res;
                } catch (err) {
                        setError(err.status);
                }
        };

        useEffect(() => {
                var pages = [];
                menuContent.forEach((section) => {
                        section.items.forEach((page) => {
                                pages.push(`${section.route}${page.route}`);
                        });
                });

                if (!pages.includes(location.pathname)) {
                        setLoading(false);
                        setError(404);
                } else {
                        getTeacherData().then(res => {
                                if (res) {
                                        setTeacherData(res.data);
                                } else {
                                        setTeacherData(null);
                                }
                                setLoading(false);
                        });
                }
        }, []);

        // Render loading, error, or data
        if (loading) return <p>Loading...</p>;
        if (error) {
                return (
                        <div className="page-box">
                                <div className="page-box-content">
                                        <div className="error-code">{error}</div>
                                        <div className="error-date">
                                                {new Date().toUTCString()}
                                        </div>
                                        <div className="error-title">
                                                Something went wrong.
                                        </div>
                                        <div className="error-subtitle">
                                                {error === 404 ? "The page you're looking for doesn't exist." : "You may not have access to this page."}
                                        </div>
                                </div>
                        </div>
                )
        }

        return (
                <TeacherContext.Provider value={teacherData}>
                        {props.children}
                </TeacherContext.Provider>
        );
};

export const useTeacherContext = () => useContext(TeacherContext);
