import React from "react";

const Notification = (props) => {
        return (
                <>
                        <div className="info-banner">
                                <div className="info-icon">i</div>
                                <span>
                                        {props.text}
                                        <br />
                                        <span className="info-subtext">
                                                {props.subtext}
                                        </span>
                                </span>
                        </div>
                        <div className="spacer-25"></div>
                </>
        );
}

export default Notification;