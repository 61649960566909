import React, { createContext, useContext } from 'react';

export const GeneralContext = createContext();

export const GeneralContextProvider = (props) => {
        const generals = {
                ID_OFFSET: 3120,
                YEAR: 2024,
                SERIES_YEAR: "2024/25"
        };

        return (
                <GeneralContext.Provider value={generals}>
                        {props.children}
                </GeneralContext.Provider>
        );
};

export const useGeneralContext = () => useContext(GeneralContext);