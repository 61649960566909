import React, { useEffect, useState } from "react";
import { useCaretPosition } from 'react-use-caret-position';

const handleKeyDown = (event) => {
        const currentContent = event.target.value.trim();

        if (event.key === 'Backspace' && currentContent.length === 0) {
                event.preventDefault();
        }

        // Prevent the user from adding more than 2 numbers
        if (currentContent.length >= 2 && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Up'].includes(event.key)) {
                event.preventDefault();
                // return;
        }

        // Prevent the user from pressing Enter
        if (event.key === 'Enter') {
                event.preventDefault();
        }

        // Allow only numbers and specific keys (e.g., backspace, delete)
        if (!/[0-9]/.test(event.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Up'].includes(event.key)) {
                event.preventDefault();
        }
};

const MarksInput = (props) => {
        const { inputRef, updateCaret } = useCaretPosition();

        const scores = props.scores;
        const question = props.question;

        const handleChange = (event) => {
                props.handleInput(event, question);
                updateCaret();
        };

        return (
                <input
                        className="table-row-item centred value marks-input"
                        ref={inputRef}
                        contentEditable
                        suppressContentEditableWarning={true}
                        onKeyDown={handleKeyDown}
                        onInput={handleChange}
                        value={scores[question] === null || scores[question] === undefined ? "" : scores[question]}
                />
        );
};

export default MarksInput;