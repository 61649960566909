import React, { useEffect, useState } from "react";

const ProfileBox = (props) => {
        const [initials, setInitials] = useState("");
        
        useEffect(() => {
                setInitials(props.teacher.name.split(" ").map((name) => name[0]).join(""));
        }, []);

        return (
                <div className="nav-profile">
                        <div class="profile-icon">{initials}</div>
                        <div class="profile-content">
                                <div class="profile-name">{props.teacher.name}</div>
                                <div class="profile-school">{props.teacher.school}</div>
                        </div>
                </div>
        );
};

export default ProfileBox;