import JSZip from 'jszip';
import { jsPDF } from 'jspdf';

export const loadFonts = async () => {
        try {
                const boldBAOFontResponse = await fetch('/fonts/BAOFont-Bold-base64.txt');
                const regularBAOFontResponse = await fetch('/fonts/BAOFont-Regular-base64.txt');
                const boldCMUSerifResponse = await fetch('/fonts/CMUSerif-Bold-base64.txt');
                const regularCMUSerifResponse = await fetch('/fonts/CMUSerif-Regular-base64.txt');

                const boldBAOFontData = await boldBAOFontResponse.text();
                const regularBAOFontData = await regularBAOFontResponse.text();
                const boldCMUSerifData = await boldCMUSerifResponse.text();
                const regularCMUSerifData = await regularCMUSerifResponse.text();

                return { boldBAOFontData, regularBAOFontData, boldCMUSerifData, regularCMUSerifData };
        } catch (error) {
                console.error('Error loading fonts:', error);
                throw error;
        }
};

export const generateCertificates = (results, schoolName, year, fonts) => {
        const zip = new JSZip();
        const pdfPromises = [];

        for (let level in results) {
                results[level].map((student) => {
                        const pdf = createCertificate(student.name, schoolName, student.level, student.finalist, year, fonts).output('blob');
                        const filename = `${student.name} - BAO ${year} Certificate.pdf`;
                        zip.file(filename, pdf);

                        pdfPromises.push(new Promise(resolve => {
                                const reader = new FileReader();
                                reader.onload = function () {
                                        resolve(reader.result);
                                };
                                reader.readAsDataURL(pdf);
                        }));
                });
        }

        Promise.all(pdfPromises).then(results => {
                zip.generateAsync({ type: 'blob' }).then(zipBlob => {
                        const zipFilename = `${schoolName}.zip`;
                        const zipUrl = URL.createObjectURL(zipBlob);

                        // Create a link and trigger a click event to download the zip file
                        const link = document.createElement('a');
                        link.href = zipUrl;
                        link.download = zipFilename;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                });
        });
};

export const createCertificate = (name, school, level, finalist, year, fonts) => {
        const colourMap = {
                finalist: [33, 52, 90],
                gold: [200, 156, 92],
                silver: [117, 117, 118],
                bronze: [157, 102, 84],
                award: [84, 160, 187],
        };

        const pdf = new jsPDF('landscape');

        pdf.addFileToVFS('BAOFont-Bold.ttf', fonts['boldBAOFontData']);
        pdf.addFileToVFS('BAOFont-Regular.ttf', fonts['regularBAOFontData']);
        pdf.addFileToVFS('CMUSerif-Bold.ttf', fonts['boldCMUSerifData']);
        pdf.addFileToVFS('CMUSerif-Regular.ttf', fonts['regularCMUSerifData']);

        pdf.addFont('BAOFont-Bold.ttf', 'Modern', 'bold');
        pdf.addFont('BAOFont-Regular.ttf', 'Modern', 'regular');
        pdf.addFont('CMUSerif-Bold.ttf', 'Serif', 'bold');
        pdf.addFont('CMUSerif-Regular.ttf', 'Serif', 'regular');

        pdf.setFont('Modern', 'bold');

        if (finalist) {
                pdf.addImage(`/certificates/finalist-certificate.png`, 'PNG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
        } else {
                pdf.addImage(`/certificates/${level.toLowerCase()}-certificate.png`, 'PNG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
        }

        pdf.setFontSize(34);
        pdf.setTextColor(32, 53, 90);
        pdf.text(`${year}`, 148.5, 61, { align: 'center' });

        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(26);
        pdf.text(`British Algorithmic Olympiad`, 148.5, 73, { align: 'center' });

        pdf.setFont('Serif', 'bold');
        pdf.setTextColor(...colourMap[level.toLowerCase()]);
        pdf.setFontSize(80);
        if (level == "AWARD") level = "CERTIFICATE";
        pdf.text(level.toUpperCase(), 148.5, 110, { align: 'center' });

        var offset = 0;

        if (finalist) {
                pdf.setFontSize(14);
                pdf.setFont('times', 'bold');
                pdf.setTextColor(0, 0, 0);
                pdf.text('&', 130, 121, { align: 'center' })

                pdf.setFontSize(20);
                pdf.setFont('Serif', 'bold');
                pdf.setTextColor(...colourMap['finalist']);
                pdf.text('FINALIST', 154, 121, { align: 'center' });

                offset = 11;
        }

        pdf.setFont('Serif', 'regular');
        pdf.setTextColor(0, 0, 0);
        pdf.setFontSize(14);
        pdf.text(`Awarded to`, 148.5, 130 + offset, { align: 'center' });

        pdf.setFont('Serif', 'bold');
        pdf.setFontSize(23);
        pdf.text(name, 148.5, 141 + offset, { align: 'center' });

        pdf.setFont('Serif', 'regular');
        pdf.setFontSize(14);
        pdf.text(`of`, 148.5, 155.5 + offset, { align: 'center' });

        pdf.setFontSize(17);
        pdf.text(school, 148.5, 164 + offset, { align: 'center' });

        return pdf;
}