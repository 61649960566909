import React, { useEffect, useState } from "react";
import Notification from "../../components/Notification";

const Settings = () => {

        return (
                <Notification text="This page is not available until next year." subtext="Please contact us if you wish to change your details." />
        );
};

export default Settings;