import React from "react";
import Event from "../../components/Event";

const events = [
        {
                id: 1,
                title: "Registration",
                dateString: "16 OCT 2024 - 6 DEC 2024",
                startDate: new Date("2024-10-16T07:00:00"),
                endDate: new Date("2024-12-06T18:00:00"),
                description: "Register your students to compete in the 2024 BAO.",
        },
        {
                id: 2,
                title: "Round One",
                dateString: "11 NOV 2024 - 6 DEC 2024",
                startDate: new Date("2024-11-11T07:00:00"),
                endDate: new Date("2024-12-06T18:00:00"),
                description: "Your students can sit the exam at any point in this period.",
        },
        {
                id: 3,
                title: "Submission",
                dateString: "11 NOV 2024 - 11 DEC 2024",
                startDate: new Date("2024-11-11T07:00:00"),
                endDate: new Date("2024-12-11T18:00:00"),
                description: "Add and update your students’ marks until the deadline.",
        },
        {
                id: 4,
                title: "Round One Results",
                dateString: "8 JAN 2025",
                startDate: new Date("2025-01-08T07:00:00"),
                endDate: new Date("2025-01-08T07:00:00"),
                description: "View your students’ results and certificates, including any final round invitations.",
        },
        {
                id: 5,
                title: "Final Round",
                dateString: "TBC MAR 2025",
                startDate: new Date("2025-03-03T07:00:00"),
                endDate: new Date("2025-03-03T07:00:00"),
                description: "The final round of the 2024/25 BAO for those invited following round one.",
        },
        {
                id: 6,
                title: "Final Results",
                dateString: "TBC APR 2025",
                startDate: new Date("2025-04-03T07:00:00"),
                endDate: new Date("2025-04-03T07:00:00"),
                description: "The results for the final round of the competition.",
        }
];

const Timeline = () => {
        const currentDate = new Date();

        return (
                <div className="timeline">
                        <div className="timeline-line"></div>
                        {events.map((event) => (
                                <Event event={event} currentDate={currentDate} key={event.id} />
                        ))}
                </div>
        );
};

export default Timeline;