import React from "react";
import Login from "../screens/auth/Login";
import Register from "../screens/auth/Register";

const Authentication = (props) => {
        const renderForm = () => {
                if (props.page === "register") return (<Register />);
                else return (<Login />);
        };

        return (
                <div className="auth-body">
                        <div className="auth-section">
                                <div className="auth-logo">
                                        <img src="./logo-inverted.png" className="nav-icon" />
                                        <div className="nav-title">BAO <span className="lb-text-inverted">Portal</span></div>
                                </div>
                                {renderForm()}
                        </div>
                        <div className="auth-image"></div>
                </div>

        );
}

export default Authentication;