import React, { useEffect, useState } from "react";
import axios from "axios";
import Notification from "../../components/Notification";
import ResultCard from "../../components/ResultCard";
import { useTeacherContext } from "../../contexts/TeacherContext";
import { loadFonts, createCertificate, generateCertificates } from "../../utils/certificateUtils";
const { useTimeContext } = require("../../contexts/TimeContext");
const { useGeneralContext } = require("../../contexts/GeneralContext");

const Results = () => {
        const [results, setResults] = useState({
                "GOLD": [], "SILVER": [], "BRONZE": [], "AWARD": []
        });

        const generals = useGeneralContext();
        const teacher = useTeacherContext();
        const times = useTimeContext();

        const start = new Date(times.RESULTS_RELEASE);
        const currentDate = new Date();

        const [fonts, setFonts] = useState(null);
        const [loading, setLoading] = useState(true);

        const generateCertificate = (result) => {
                const pdf = createCertificate(result.name, teacher.school, result.level, result.finalist, generals.YEAR, fonts);
                pdf.save(`${result.name} - Certificate BAO 2024.pdf`);
        };

        const generateAllCertificates = () => {
                generateCertificates(results, teacher.school, generals.YEAR, fonts);
        };

        const getResults = async () => {
                try {
                        const res = await axios.get("/api/teachers/getResults", {
                                withCredentials: true
                        });

                        setResults(res.data);
                        setLoading(false);
                } catch (err) {
                        console.log(err);
                        setLoading(false);
                }
        };

        useEffect(() => {
                loadFonts().then(fonts => {
                        setFonts(fonts);
                });

                getResults();
        }, []);

        if (loading) {
                return (
                        <div className="loading">Loading...</div>
                );
        }

        return (
                <>
                        {(currentDate < start) ? (
                                <Notification text="This page is not yet available." subtext="Results are released on the 8th January 2025 at 07:00." />
                        ) : (
                                <>
                                        <div className="download-certificates-btn" onClick={generateAllCertificates}>Download all certificates</div>
                                        <div className="results">
                                                <div className="results-group-title">
                                                        Gold Awards
                                                        <span className="results-group-title-number">{results.GOLD.length}</span>
                                                </div>
                                                <div className="results-group">
                                                        {results.GOLD.map(result => (
                                                                <ResultCard key={result.id} result={result} generateCertificate={generateCertificate} />
                                                        ))}
                                                </div>
                                                <div className="results-group-title">
                                                        Silver Awards
                                                        <span className="results-group-title-number">{results.SILVER.length}</span>
                                                </div>
                                                <div className="results-group">
                                                        {results.SILVER.map(result => (
                                                                <ResultCard key={result.id} result={result} generateCertificate={generateCertificate} />
                                                        ))}
                                                </div>
                                                <div className="results-group-title">
                                                        Bronze Awards
                                                        <span className="results-group-title-number">{results.BRONZE.length}</span>
                                                </div>
                                                <div className="results-group">
                                                        {results.BRONZE.map(result => (
                                                                <ResultCard key={result.id} result={result} generateCertificate={generateCertificate} />
                                                        ))}
                                                </div>
                                                <div className="results-group-title">
                                                        Olympiad Awards
                                                        <span className="results-group-title-number">{results.AWARD.length}</span>
                                                </div>
                                                <div className="results-group">
                                                        {results.AWARD.map(result => (
                                                                <ResultCard key={result.id} result={result} generateCertificate={generateCertificate} />
                                                        ))}
                                                </div>
                                        </div>
                                </>

                        )}
                </>
        );
};

export default Results;