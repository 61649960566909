import React from "react";
import { Routes, Route } from "react-router-dom";
import TitleBar from "./TitleBar";
import PanelContent from "./PanelContent";
import Registration from "../screens/app/Registration";
import Submission from "../screens/app/Submission";
import Results from "../screens/app/Results";
import Timeline from "../screens/app/Timeline";
import Documents from "../screens/app/Documents";
import Settings from "../screens/app/Settings";

const ContentArea = () => {
        return (
                <div className="right-panel">
                        <TitleBar />
                        <PanelContent>
                                <Routes>
                                        <Route path="/" element={<Registration />} />
                                        <Route path="/students/registrations" element={<Registration />} />
                                        <Route path="/students/submissions" element={<Submission />} />
                                        <Route path="/students/results" element={<Results />} />
                                        <Route path="/competition/timeline" element={<Timeline />} />
                                        <Route path="/competition/documents" element={<Documents />} />
                                        <Route path="/profile/settings" element={<Settings />} />
                                </Routes>
                        </PanelContent>
                </div>
        );
}

export default ContentArea;