import React from "react";

const PanelContent = (props) => {
        return (
                <div className="panel-content">
                        {props.children}
                </div>
        );
};

export default PanelContent;