import React, { useState, createContext, useContext } from 'react';

export const QuestionsContext = createContext();

export const QuestionsContextProvider = (props) => {
        const [questions, setQuestions] = useState(
                ["1A", "1B", "1C", "1D", "2A", "2B", "2C", "3A", "3B", "3C", "3D", "4A", "4B", "4C"]
        );

        return (
                <QuestionsContext.Provider value={questions}>
                        {props.children}
                </QuestionsContext.Provider>
        );
};

export const useQuestionsContext = () => useContext(QuestionsContext);
