import React, { useEffect, useState } from "react";

const UnverifiedTab = () => {
        return (
                <div className="unverified-tab">
                        <div className="unverified-tab-title">Unverified</div>
                        <div className="unverified-tab-content">
                                <div className="unverified-tab-message">Your account status is currently: <b>unverified</b>.</div>
                                <div className="unverified-tab-message">If your account meets our criteria, you should be verified within 24 hours of your registration.</div>
                        </div>
                </div>
        );
}

export default UnverifiedTab;