import React, { useEffect, useState } from "react";
import axios from "axios";
import Notification from "../../components/Notification";
import DocumentsTable from "../../components/DocumentsTable";

const Documents = () => {

        // const documents = [
        //         // {
        //         //         "filename": "bao2024.pdf",
        //         //         "name": "BAO 2024 Exam Paper",
        //         //         "uploaded": "11/11/2024 at 07:00",
        //         //         "owner": "British Algorithmic Olympiad"
        //         // },
        //         // {
        //         //         "filename": "bao2024-MS.pdf",
        //         //         "name": "BAO 2024 Mark Scheme",
        //         //         "uploaded": "11/11/2024 at 07:00",
        //         //         "owner": "British Algorithmic Olympiad"
        //         // },
        //         {
        //                 "filename": "teacher-guide.pdf",
        //                 "name": "Marking & Invigilation Guide",
        //                 "uploaded": "11/11/2024 at 07:00",
        //                 "owner": "British Algorithmic Olympiad"
        //         }
        // ];

        const [documents, setDocuments] = useState([]);

        const getDocuments = async () => {
                const res = await axios.get("/api/documents/getDocuments", {
                        withCredentials: true
                });

                return res.data;
        };

        useEffect(() => {
                try {
                        getDocuments().then(documents => {
                                setDocuments(documents);
                        });
                } catch (err) {
                        console.log(err);
                }
        }, []);

        return (
                <>
                        <Notification text="All documents on this page are confidential. Please do not distribute digital copies." subtext="Failure to comply may lead to disqualification." />
                        <DocumentsTable documents={documents} />
                </>
        );
};

export default Documents;