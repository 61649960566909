import React, { Fragment } from "react";
import MenuItem from "./MenuItem";
import { useMenuContext } from "../contexts/MenuContext";

const Menu = () => {
        const menuContent = useMenuContext();

        return (
                <div className="left-panel">
                        <div className="menu">
                                {menuContent.map((section) => (
                                        <Fragment key={section.title}>
                                                <div className="menu-section-title">{section.title}</div>
                                                {section.items.map((item) => (
                                                        <MenuItem key={item.route} item={item} section={section} />
                                                ))}
                                        </Fragment>
                                ))}
                        </div>
                </div>
        );
}

export default Menu;